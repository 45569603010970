import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>El Curs</Title>

                <Text>
                    20 hores en directe a través de ZOOM
                    <br />
                    <br />
                    Et presentem el millor curs d'anglès per preparar la prova d'idioma de la
                    convocatòria de la Guàrdia Urbana de Barcelona.
                    <br />
                    <br />
                    A la oposició és super important la prova d'idioma, ja que pots sumar fins a 5
                    punts extra.
                    <br />
                    <br />
                    El curs està dividit en dues grans temàtiques:
                    <br />
                    <br />
                    Per una banda, un campus online amb més de 150 exercicis autocorregibles
                    <br />
                    <br />
                    D'altra banda 20 hores en directe a través de ZOOM
                    <br />
                    <br />
                    A banda de la gramàtica, cada classe inclou exercicis de preparació específics
                    per a l'examen
                    <br />
                    <br />
                    Reading, fill-in the gaps, etc...
                    <br />
                    <br />
                    Un cop ple el curs es farà un grup de WhatsApp amb contacte directe amb el
                    professor i totes les classes quedaran gravades fins al dia de l'examen.
                    <br />
                    <br />A mesura que s'apropi la nova convocatòria de la Guàrdia Urbana de
                    Barcelona tornarem a portar aquesta formació.
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>

                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/Zd6Og0FCmSI"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
